//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import LoginServices from "../../Services/LoginServices";

const loginServices = new LoginServices();
export default {
  name: "Template",
  components: {
    menuTemplate: () => import("../menus/Menu")
  },
  data() {
    return {
      menu: false,
      pin: "",
      cajaId: "",
      tituloLogin: "",
    }
  },
  mounted() {
    let tipoMenu = localStorage.getItem("menu") === null ? 0 : localStorage.getItem("menu");
    this.pin = localStorage.getItem("pin");
    this.cajaId = localStorage.getItem("cajaId");
    this.tituloLogin = localStorage.getItem("tituloLogin");
    this.cargarMenu(Number(tipoMenu));
    loginServices.cargarDatosIniciales();
  },
  methods: {
    cargarMenu(tipoMenu) {
      if (tipoMenu === 1) {
        this.menu = true;
        localStorage.setItem("menu", "1");
      }
    },
    cerrarSession() {
      let token = JSON.stringify({pin:this.pin,cajaId:this.cajaId,tituloLogin:this.tituloLogin});
      localStorage.clear();
      this.$router.push({name: "login", query: {token: btoa(token)}});
    }
  }
}
